
//储存前端客户的信息 不读cookie可以在所有页面共享使用

import { defineStore  } from 'pinia'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    memberData : null,  // 用户信息初始为空
  }),
  actions: {
    setMemberData(user) {
      this.memberData  = user
    },
    clearMemberData() {
      this.memberData  = null
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.memberData   // 判断是否登录
  }
})
